import { AnyAction, Dispatch } from '@reduxjs/toolkit'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { WebSocket } from '@merchx-v3/web-socket'
import { Pto } from '@merchx-v3/pto'

import { settings } from 'config/settings'
import { tokenProvider } from 'app/auth/token-provider'
import { store } from 'app/store'
import { getProvidedTags } from 'helpers/getProvidedTags'

const { protocol, domain } = settings.site

export const paymentsOldApi = createApi({
  reducerPath: 'paymentsOldApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${protocol}://${domain}/api`,
    prepareHeaders: tokenProvider.prepareHeaders
  }),
  tagTypes: ['Payments', 'Project'],

  endpoints: (builder) => ({
    paymentList: builder.query<Pto.PaymentsOld.List, Pto.PaymentsOld.PaymentListQuery>({
      query: ({ page, size, startDate, endDate, status, projectId }) => ({
        url: 'payments-old',
        params: { page, size, startDate, endDate, status, projectId }
      }),
      providesTags: (paymentListData, _error, _args) =>
        getProvidedTags(
          'Payments',
          'LIST',
          paymentListData?.items.map((item) => item.id)
        )
    }),
    payManually: builder.mutation<void, Pto.PaymentsOld.PayManually>({
      query: (body) => ({
        url: 'payments-old/pay-manually',
        body,
        method: 'POST'
      }),
      invalidatesTags: [
        { type: 'Payments', id: 'LIST' },
        { type: 'Payments', id: 'TOTAL_INCOME' }
      ]
    }),
    payment: builder.query<Pto.Projects.Project, string>({
      query: (paymentId) => ({
        url: `payments-old/${paymentId}`
      }),
      providesTags: (_result, _error, paymentId) => getProvidedTags('Payments', paymentId)
    }),
    refund: builder.mutation<void, Pto.PaymentsOld.RefundPayment>({
      query: (body) => ({
        url: 'payments-old/refund',
        body,
        method: 'POST'
      }),
      invalidatesTags: [
        { type: 'Payments', id: 'LIST' },
        { type: 'Payments', id: 'TOTAL_INCOME' }
      ]
    }),
    projectTotalIncome: builder.query<number, string>({
      query: (projectId) => ({
        url: `/payments-old/projects/${projectId}/total-income`
      }),
      providesTags: (_result, _error, _args) => getProvidedTags('Payments', 'TOTAL_INCOME')
    })
  })
})

const onEntityUpdated = (payload: WebSocket.Channels.Listeners.EntityUpdatedPayload) => {
  if (payload.entityType === 'Payment') {
    store.dispatch(paymentsOldApi.util.invalidateTags([{ type: 'Payments', id: payload.entityId }]))
  }
}

export const subscribeToPaymentOldEvents = (socket: WebSocket.MxWebSocket, dispatch: Dispatch<AnyAction>) => {
  socket.on('entity-updated', onEntityUpdated)
}

export const unsubscribeFromPaymentOldEvents = (socket: WebSocket.MxWebSocket) => {
  socket.off('entity-updated', onEntityUpdated)
}

export const { usePaymentListQuery, usePayManuallyMutation, usePaymentQuery, useProjectTotalIncomeQuery, useRefundMutation } = paymentsOldApi
