import { WebSocket } from '@merchx-v3/web-socket'
import { Middleware } from '@reduxjs/toolkit'

import { subscribeToCartEvents, unsubscribeFromCartEvents } from './api/carts-api'
import { subscribeToConversationEvents, unsubscribeFromConversationEvents } from './api/conversations-api'
import { subscribeToCustomerEvents, unsubscribeFromCustomerEvents } from './api/customers-api'
import { subscribeToCustomGatewaySettingEvents, unsubscribeFromCustomGatewaySettingEvents } from './api/custom-gateway-settings-api'
import { subscribeToDesignRequestEvents, unsubscribeFromDesignRequestEvents } from './api/design-requests'
import { subscribeToDesignTemplateEvents, unsubscribeFromDesignTemplateEvents } from './api/design-templates-api'
import { subscribeToDesignEvents, unsubscribeFromDesignEvents } from './api/designs-api'
import { subscribeToEcomProductEvents, unsubscribeFromEcomProductEvents } from './api/ecom-products-api'
import { subscribeToFulfillmentTaskEvents, unsubscribeFromFulfillmentTaskEvents } from './api/fulfillment-tasks-api'
import { subscribeToPaymentOldEvents, unsubscribeFromPaymentOldEvents } from './api/payments-old-api'
import { subscribeToPaymentEvents, unsubscribeFromPaymentEvents } from './api/payments-api'
import { subscribeToPluginEvents, unsubscribeFromPluginEvents } from './api/plugins-api'
import { subscribeToProcessingTemplateEvents, unsubscribeFromProcessingTemplateEvents } from './api/processing-templates-api'
import { subscribeToProjectEvents, unsubscribeFromProjectEvents } from './api/projects-api'
import { subscribeToSagaEvents, unsubscribeFromSagaEvents } from './api/sagas-api'
import { subscribeToSaleSourceEvents, unsubscribeFromSaleSourceEvents } from './api/sale-sources-api'
import { subscribeToShipmentEvents, unsubscribeFromShipmentEvents } from './api/shipments-api'
import { subscribeToShipstationSettingEvents, unsubscribeFromShipstationSettingEvents } from './api/shipstation-settings-api'
import { subscribeToSupplierProductEvents, unsubscribeFromSupplierProductEvents } from './api/supplier-products-api'
import { subscribeToSupplierOrderDesignEvents, unsubscribeFromSupplierOrderDesignEvents } from './api/supplier-order-designs-api'
import { subscribeToSupplierOrderFulfillmentsEvents, unsubscribeFromSupplierOrderFulfillmentsEvents } from './api/supplier-order-fulfillments-api'
import { subscribeToSupplierOrderShippingsEvents, unsubscribeFromSupplierOrderShippingsEvents } from './api/supplier-order-shippings-api'
import { subscribeToSupplierEvents, unsubscribeFromSupplierEvents } from './api/suppliers-api'
import { subscribeToUserEvents, unsubscribeFromUserEvents } from './api/users-api'
import { subscribeToSupplierOrderEvents, unsubscribeFromSupplierOrdersEvents } from './api/supplier-orders-api'

import { subscribeSliceToSagaEvents, unsubscribeSliceFromSagaEvents } from 'interfaces/admin/features/sagas/sagasSlice'
import { subscribeToTagEvents, unsubscribeFromTagEvents } from './api/tags-api'

type WebSocketMiddleware = (socket: WebSocket.MxWebSocket) => Middleware

// const protocol = process.env.REACT_APP_PROTOCOL
// const domain = process.env.REACT_APP_DOMAIN

export const webSocketMiddleware: WebSocketMiddleware = (socket: WebSocket.MxWebSocket) => (params) => (next) => (action) => {
  const { dispatch, getState } = params
  const { type, payload } = action

  switch (type) {
    case 'socket/connect':
      // Put other subscriptions after that line

      subscribeSliceToSagaEvents(socket, dispatch)

      subscribeToCartEvents(socket, dispatch)
      subscribeToConversationEvents(socket, dispatch)
      subscribeToCustomerEvents(socket, dispatch)
      subscribeToCustomGatewaySettingEvents(socket, dispatch)
      subscribeToDesignRequestEvents(socket, dispatch)
      subscribeToDesignTemplateEvents(socket, dispatch)
      subscribeToDesignEvents(socket, dispatch)
      subscribeToEcomProductEvents(socket, dispatch)
      subscribeToFulfillmentTaskEvents(socket, dispatch)
      subscribeToPaymentOldEvents(socket, dispatch)
      subscribeToPaymentEvents(socket, dispatch)
      subscribeToPluginEvents(socket, dispatch)
      subscribeToProcessingTemplateEvents(socket, dispatch)
      subscribeToProjectEvents(socket, dispatch)
      subscribeToSagaEvents(socket, dispatch)
      subscribeToSaleSourceEvents(socket, dispatch)
      subscribeToShipmentEvents(socket, dispatch)
      subscribeToShipstationSettingEvents(socket, dispatch)
      subscribeToSupplierProductEvents(socket, dispatch)
      subscribeToSupplierEvents(socket, dispatch)
      subscribeToSupplierOrderEvents(socket, dispatch)
      subscribeToSupplierOrderDesignEvents(socket, dispatch)
      subscribeToSupplierOrderFulfillmentsEvents(socket, dispatch)
      subscribeToSupplierOrderShippingsEvents(socket, dispatch)
      subscribeToUserEvents(socket, dispatch)
      subscribeToTagEvents(socket, dispatch)
      break

    case 'socket/disconnect':
      unsubscribeFromCartEvents(socket)
      unsubscribeFromConversationEvents(socket)
      unsubscribeFromCustomerEvents(socket)
      unsubscribeFromCustomGatewaySettingEvents(socket)
      unsubscribeFromDesignRequestEvents(socket)
      unsubscribeFromDesignTemplateEvents(socket)
      unsubscribeFromDesignEvents(socket)
      unsubscribeFromEcomProductEvents(socket)
      unsubscribeFromFulfillmentTaskEvents(socket)
      unsubscribeFromPaymentOldEvents(socket)
      unsubscribeFromPaymentEvents(socket)
      unsubscribeFromPluginEvents(socket)
      unsubscribeFromProjectEvents(socket)
      unsubscribeFromProcessingTemplateEvents(socket)
      unsubscribeFromSagaEvents(socket)
      unsubscribeFromSaleSourceEvents(socket)
      unsubscribeFromShipmentEvents(socket)
      unsubscribeFromShipstationSettingEvents(socket)
      unsubscribeFromSupplierProductEvents(socket)
      unsubscribeFromSupplierEvents(socket)
      unsubscribeFromSupplierOrdersEvents(socket)
      unsubscribeFromSupplierOrderDesignEvents(socket)
      unsubscribeFromSupplierOrderFulfillmentsEvents(socket)
      unsubscribeFromSupplierOrderShippingsEvents(socket)
      unsubscribeFromUserEvents(socket)
      unsubscribeFromTagEvents(socket)

      unsubscribeSliceFromSagaEvents(socket, dispatch)

      // Put other subscriptions before
      break

    default:
      break
  }

  return next(action)
}
