import React from 'react'

type Props = {
  colors: string[]
}

const createColorGradient = (colorsArray: string[]): string => {
  if (colorsArray.length === 1) {
    return colorsArray[0]
  } else if (colorsArray.length === 2) {
    return `linear-gradient(90deg, ${colorsArray[0]} 50%, ${colorsArray[1]} 50%)`
  } else if (colorsArray.length === 3) {
    return `conic-gradient(from 180deg at 50% 50%, ${colorsArray[0]} 0deg, ${colorsArray[0]} 90deg, ${colorsArray[1]} 90deg, ${colorsArray[1]} 180deg, ${colorsArray[2]} 180deg, ${colorsArray[2]} 360deg)`
  } else if (colorsArray.length === 4) {
    return `conic-gradient(
      from 180deg at 50% 50%, 
      ${colorsArray[0]} 0deg 90deg, 
      ${colorsArray[1]} 90deg 180deg, 
      ${colorsArray[2]} 180deg 270deg, 
      ${colorsArray[3]} 270deg 360deg
    )`
  } else {
    const step = 360 / colorsArray.length
    const gradientPoints = colorsArray
      .map((color, index) => `${color} ${(index * step)}deg`)
      .join(', ')
    return `conic-gradient(from 180deg at 50% 50%, ${gradientPoints})`
  }
}

export const ColorCircle = ({ colors }: React.PropsWithChildren<Props>) => {
  const background = createColorGradient(colors)

  return (
    <div
      style={{
        display: 'flex',
        width: '16px',
        height: '16px',
        borderRadius: '50%',
        background,
        border: '1px solid #ccc'
      }}
    />
  )
}

export default ColorCircle
