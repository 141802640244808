import { Pto } from '@merchx-v3/pto'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { settings } from 'config/settings'
import { tokenProvider } from 'app/auth/token-provider'

const { protocol, domain } = settings.site

type WebhookId = string
type EventId = string

export const stripeWebhooksApi = createApi({
  reducerPath: 'stripeWebhooksApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${protocol}://${domain}/api`,
    prepareHeaders: tokenProvider.prepareHeaders
  }),
  tagTypes: ['Subscriptions'],
  endpoints: (builder) => ({
    subscriptionsList: builder.query<Pto.StripeWebhooks.SubscriptionList, Pto.StripeWebhooks.SubscriptionListQuery>({
      query: (params) => ({
        url: 'payments/stripe/webhooks',
        params
      })
    }),
    subscription: builder.query<Pto.StripeWebhooks.Subscription, WebhookId>({
      query: (webhookId) => ({
        url: `payments/stripe/webhooks/${webhookId}`
      })
    }),
    webhooks: builder.query<Pto.StripeWebhooks.WebhookList, Pto.StripeWebhooks.WebhookListQuery & { webhookId: WebhookId }>({
      query: ({ webhookId, ...params }) => ({
        url: `payments/stripe/webhooks/${webhookId}/list`,
        params
      })
    }),
    webhook: builder.query<Pto.StripeWebhooks.Webhook, EventId>({
      query: (eventId) => ({
        url: `payments/stripe/webhooks/${eventId}/event`
      })
    }),
    subscribe: builder.mutation<void, Pto.StripeWebhooks.AddWebhookEndpoint>({
      query: (body) => ({
        url: 'payments/stripe/webhooks/subscribe',
        body,
        method: 'PUT'
      })
    }),
    unsubscribe: builder.mutation<void, { webhookId: string }>({
      query: ({ webhookId }) => ({
        url: `payments/stripe/webhooks/${webhookId}/unsubscribe`,
        method: 'DELETE'
      })
    })
  })
})

export const { useSubscriptionsListQuery, useSubscriptionQuery, useWebhooksQuery, useLazyWebhookQuery, useSubscribeMutation, useUnsubscribeMutation } = stripeWebhooksApi
