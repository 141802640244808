import { AnyAction, Dispatch } from '@reduxjs/toolkit'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { WebSocket } from '@merchx-v3/web-socket'
import { Pto } from '@merchx-v3/pto'

import { settings } from 'config/settings'
import { tokenProvider } from 'app/auth/token-provider'
import { store } from 'app/store'
import { getProvidedTags } from 'helpers/getProvidedTags'

const { protocol, domain } = settings.site

export const paymentsApi = createApi({
  reducerPath: 'paymentsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${protocol}://${domain}/api`,
    prepareHeaders: tokenProvider.prepareHeaders
  }),
  tagTypes: ['Payments'],

  endpoints: (builder) => ({
    paymentList: builder.query<Pto.Payments.List, Pto.Payments.PaymentListQuery>({
      query: (params) => ({
        url: 'payments',
        params
      }),
      providesTags: (paymentListData, _error, args) =>
        getProvidedTags(
          'Payments',
          `${args.source}_${args.sourceId}`,
          paymentListData?.items.map((item) => item.id)
        )
    }),
    refund: builder.mutation<void, Pto.Payments.Refund>({
      query: (body) => ({
        url: 'payments/refund',
        body,
        method: 'POST'
      })
    })
  })
})

const onEntityUpdated = (payload: WebSocket.Channels.Listeners.EntityUpdatedPayload) => {
  console.log('wtf???222222222222222222221')

  if (payload.entityType !== 'Payment') return

  if (payload.channel === 'carts') {
    store.dispatch(paymentsApi.util.invalidateTags([{ type: 'Payments', id: `${Pto.Payments.Source.SharedCart}_${payload.entityId}` }]))
    store.dispatch(paymentsApi.util.invalidateTags([{ type: 'Payments', id: `${Pto.Payments.Source.Cart}_${payload.entityId}` }]))
  }

  if (payload.channel === 'supplier-orders') {
    store.dispatch(paymentsApi.util.invalidateTags([{ type: 'Payments', id: `${Pto.Payments.Source.SupplierOrder}_${payload.entityId}` }]))
  }
}

export const subscribeToPaymentEvents = (socket: WebSocket.MxWebSocket, dispatch: Dispatch<AnyAction>) => {
  socket.on('entity-updated', onEntityUpdated)
}

export const unsubscribeFromPaymentEvents = (socket: WebSocket.MxWebSocket) => {
  socket.off('entity-updated', onEntityUpdated)
}

export const { usePaymentListQuery, useRefundMutation } = paymentsApi
